import { Injectable } from '@angular/core';

import { HttpService } from 'src/app/shared/services/http.service';
import { AlertService } from 'src/app/shared/services/alert.service';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AssignedTestsService {

  dateRangeValue = new BehaviorSubject(null);
  initializeFilter = new BehaviorSubject(null); // For Filter Scroll
  filterRetention: any = {
    testHistory : '',
    assignment:  '',
    upcomingTest: '',
    previousYearPapers: '',
  }

  constructor(
    private httpService: HttpService,
    private alertService: AlertService
  ) { }

  getAssignedTests(body): any {
    return new Promise((resolve, reject) => {
      this.httpService.post('v4/auth/assigned-tests', true, body, true, false, false).subscribe((res) => {
        if (res && res.code === 200) {
          resolve(res.data);
        } else {
          this.alertService.showErrorMessage(res.message);
        }
      });
    });
  }
}
