import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpService } from 'src/app/shared/services/http.service';
import { environment } from 'src/environments/environment';

@Injectable()
export class ManageDeviceService {

  constructor(private http: HttpService) { }

  getDevices(): Observable<any> {
    return this.http.get("v4/auth/user-devices", true, null, true);
  }

  sendOtp(body: any): Observable<any> {
    return this.http.patch("v4/user-devices", true, body, true);
  }
  
  submitOtp(body: any): Observable<any> {
    return this.http.post("v4/user-devices/verify-otp", true, body, true);
  }
}
