export const REMEDIAL =  {
    REMEDIAL: 'remedial',
    ROUTE: {
        REMEDIAL_QUESTIONS: 'remedial-questions'
    },
    STATUS: {
        VIEW : 'view',
        START: 'notStarted',
        EMPTY: 'noQuestionForRemedial',
        notStarted: 'start',
        completed: 'view'
    },
    LABEL: {
        NEXT: 'Next',
        SUBMIT: 'Submit',
        START_REMEDIAL: 'Start Remedial Practice',
        NOT: 'not',
        INCORRECTLY : 'incorrectly',
        UNATTEMPTED: 'Unattempted',
        INCORRECT: 'Incorrect',
        ATTEMPT: 'Attempt',
        REMEDIAL_PRACTICE: 'Remedial Practice',
    },
    QUESTION_TYPE: {
        SMCQ: 0,
        MMCQ: 8,
        TRUE_FALSE: 2,
        BLANKS: 3,
        INTEGER: 7,
        NUMERICAL: 6,
        MATRIX: 1,
        PARAGRAPH: 5
    },
    REMEDIAL_SESSION: ['Start', 'Re-start', 'Resume'],
    HIDE_REMEDIAL_SESSION: ['noRemedialQuestion', 'noQuestionForRemedial'],
    RESPONSE_CODE: {
        SUCCESS: 200
    }
}