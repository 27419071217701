const BATCH_HISTORY_DATE_TYPE = {
    START_DATE: 'startDate',
    END_DATE: 'endDate',
};

const BATCH_HISTORY_SORT_TYPE = {
    ASCENDING: 'ascending',
    DESCENDING: 'descending',
};

const COLOR_TYPE = {
    ACTIVE: '#1d7dea',
    INACTIVE: '#999999'
}

const CURRENT_DATE_TEXT = 'Present';

const FACULTY_HISTORY_FETCH_FAILED = 'Unable to Fetch Faculty History.';

const BATCH_HISTORY_FETCH_FAILED = 'Unable to Fetch Batch History.';
const BITSAT_TYPE = 'BITSAT';

export { BATCH_HISTORY_DATE_TYPE, BATCH_HISTORY_SORT_TYPE, CURRENT_DATE_TEXT, FACULTY_HISTORY_FETCH_FAILED, BATCH_HISTORY_FETCH_FAILED , COLOR_TYPE, BITSAT_TYPE}