import { optionContent } from "../test/test-ongoing/model/option-content.model";

export interface QuestionRevision{
        _id: string;
        questionType: string;
        difficultyType: string [];
        difficultyLevel: string[];
        contentData: {
            qData: {
                content: QuestionContent []
            }
        },
        revision: {
            questionDetails:any; 
        },
        questionCode:any;
        attemptData?:any;
}
export interface QuestionContent {
    selectedOptions: { value: any; }[];
    solutionContent:string;
    questionHints?:string;
    questionContent:string; 
    correctAnswer: {
        answerType: string,
        data: [
            {
                value: string,
                _id: string,
                id:string
            }
        ]
    },
    stepWiseSolution: SoltuionStepWiseType [],
    matrixOptionContent: {
        optionRight: {id:string}[],
        optionLeft: {id:string}[]
    },
    optionsContent: OptionType []
}

export interface OptionType{
    serialNo: number;
    value: string;
    id:string; 
 }

export interface SoltuionStepWiseType{
        _id: string;
        formulaUsed: string;
        solutionSteps: [
            {
                solution: string;
                stepNo: number;
                _id: string;
            }
        ]
 }
 export enum PathType{
   note="note",
   video="video", 
   questionDetailView="questionDetailView", 
   similarQuestions="similarQuestions", 
   previousYearQuestions="previousYearQuestions"
 }