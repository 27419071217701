export const CARD_TITLE_TYPE = {
    EFFORT_RATING: 'effortRating',
    EXAM_TEMPARAMENT: 'examTemperament'
}

export const SWOT_DATA_TYPE = {
    CRITICAL: 50,
    AVERAGE: 75,
    ABOVE_AVERAGE: 90,
}

export const SWOT_DATA_INFO = {
    PROFICIENCY: 'Proficiency',
    ATTEMPT_RATE: 'Attempt Rate',
    ACCURACY_RATE: 'Accuracy Rate',
}
