import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class MyRevisionPlanService {
  public phaseSubject = new BehaviorSubject<any>([]);
  public appliedFilters = new BehaviorSubject<any>("");
  public viewFilters = new BehaviorSubject<any>('')
  public goalname = new BehaviorSubject<any>('');

  constructor() { }
}
