import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs/BehaviorSubject';
import { get, toLower, startCase } from 'lodash';
import { Router } from '@angular/router';
import { HttpService } from '../../shared/services/http.service';
import { environment } from 'src/environments/environment';
import { Store } from '@ngrx/store';
import * as fromApp from '../../stores/app.reducers';


export type DistributionType = 'concept' | 'chapter';

export interface ISwotPayload {
  attemptId: string;
  distribution: DistributionType;
  subjectId?: string;
}

export interface ISubject {
  id: string;
  _id: string;
  name: string;
}

export interface IDistribution {
  id: DistributionType;
  name: string;
}

export interface ISwotItem {
  _id: string;
  name: string;
  subject: string;
  attemptPercentage: number;
  marksPercentage: number;
  easyQuestionPercentage: number;
  qId?: string;
  chapter?: string;
}

export interface ISwotData {
  strength?: ISwotItem[];
  weakness?: ISwotItem[];
  opportunity?: ISwotItem[];
  threat?: ISwotItem[];
}

const DEFAULT_SWOT_DATA: ISwotData = {
  strength: [],
  weakness: [],
  opportunity: [],
  threat: []
};

export const DEFAULT_SUBJECT_SELECTION: ISubject = {
  name: 'All',
  id: '',
  _id: ''
};

export const DEFAULT_DISTRIBUTION: IDistribution = {
  name: 'Concepts',
  id: 'concept'
};

const capitalize = text => startCase(toLower(text));

@Injectable()
export class SwotService {

  constructor(private http: HttpService, private router: Router, private store: Store<fromApp.AppState>) { }

  private _subjects: ISubject[] = null;
  public subjects = new BehaviorSubject<ISubject[]>(null);
  public swotData = new BehaviorSubject<ISwotData>(null);
  public lockedSwotData = new BehaviorSubject<any>({});
  public isSwotLoading = new BehaviorSubject<boolean>(true);
  public isContentLocked = new BehaviorSubject<boolean>(false);
  public emptyScreenMessages =  new BehaviorSubject<any>({});
  public countData = new BehaviorSubject<any>({});
  public selectedTabValue = new BehaviorSubject<any>('');
  public testResultUrlParams = new BehaviorSubject<any>({});

  private _setSubjects(subjects: ISubject[]): void {
    if (!this._subjects) {
      // capitalize the name to make it more displayable
      this._subjects = subjects.map(subject => ({
        ...subject,
        name: capitalize(subject.name)
      }));
      // add the default subject to the first position
      this._subjects = [DEFAULT_SUBJECT_SELECTION].concat(this._subjects);
      // send the same info using observable
      this.subjects.next(this._subjects);
    }
  }

  private _sanitizeSwotData(swotData: ISwotData) {
    const refinedData: ISwotData = {};
    Object.keys(swotData).forEach(key => {
      refinedData[key] = swotData[key].map((swotItem: ISwotItem) => ({
        ...swotItem,
        name: capitalize(swotItem.name),
        subject: capitalize(swotItem.subject),
        chapter: swotItem.chapter ? capitalize(swotItem.chapter) : null,
      }));
    });
    return refinedData;
  }

  fetchSwotData({attemptId, distribution, subjectId}: ISwotPayload): void {
    this.isSwotLoading.next(true);
    this.http.get(`v4/auth/test-analytics/swot?attemptId=${attemptId}&subjectId=${subjectId}&type=${distribution}`, true, null, false, false, false).subscribe((res) => {

      this.isSwotLoading.next(false);
      if (res.code === 200) {
        const subjects = get(res, 'data.swot.subjects', []);
        const swotData = get(res, 'data.swot', DEFAULT_SWOT_DATA);
        this._setSubjects(subjects);
        if (res.data && res.data.emptyScreenMessages) {
          this.emptyScreenMessages.next(res.data.emptyScreenMessages);
        }
        console.log({swotData})
        this.swotData.next(swotData);
        this.countData.next({'totalCount': res.data.totalCount, 'swotCount': res.data.swotCount});
      }
      if (res.code === 204 || res.data.nonSeriousAttempt) {
        this.isContentLocked.next(true);
        this.lockedSwotData.next(res);
      }
      // if (this.router.url.includes('swot') && res && res.code === 200) {
      //   this.cleverTap.swotViewed();
      // }
    });
  }

  reset(): void {
    this._subjects = null;
    this.subjects = new BehaviorSubject<ISubject[]>(null);
    this.swotData = new BehaviorSubject<ISwotData>(null);
    this.lockedSwotData = new BehaviorSubject<any>({});
    this.isSwotLoading = new BehaviorSubject<boolean>(true);
  }

  getUrlParamsFromResult(res, routeVal): void {
    const storeData = {};
    let course;
    this.store.select('userProfile').subscribe(resNew => {
      if (resNew && resNew.selectedGoal) {
        course = resNew.selectedGoal['courseId'];
      }
    });

    storeData['attemptId'] = res.attemptId ? res.attemptId : null;
    storeData['testId'] = res.testId ? res.testId : null;
    storeData['testType'] = res.testType ? res.testType : null;
    storeData['testName'] = res.testName ? res.testName : null;
    storeData['authToken'] = res.authToken ? res.authToken : null;
    storeData['courseId'] = res.courseId ? res.courseId : course ? course : null;
    storeData['pageLoaded'] = routeVal;
    storeData['nonSeriousAttempt'] = res.nonSeriousAttempt;
    this.testResultUrlParams.next(storeData);
  }

  getSwotFilterDetails(apiPayload): any {
    return new Promise((resolve, reject) => {
      this.http.get('v4/auth/my-plan/swot?' + apiPayload, true,
        null, true
      ).subscribe((res) => {
        if (res) {
          resolve(res.data);
        } else {
          // this.alert.showErrorMessage(res.message);
        }
      });
    });
  }
}
