import { Injectable } from '@angular/core';

import { HttpService } from '../../shared/services/http.service';
import { AlertService } from '../../shared/services/alert.service';
import { environment } from 'src/environments/environment';
import { BehaviorSubject, Observable } from 'rxjs';
import { BITSAT_TYPE } from '../cards-detail/right-selected-card/my-goals/my-goals.constants';

@Injectable({
  providedIn: 'root'
})
export class MyGoalsService {
  public displayFacultyModalData = new BehaviorSubject<string>('');
  constructor(private http: HttpService, private alert: AlertService) { }

  findCourseType(courses, isSwot?) {
    const courseData = [];
    courses?.forEach(course => {
      const value = isSwot ? course?.courseType?.name : course?.courseTypeName;
      if (value !== BITSAT_TYPE) {
        courseData.push(course);
      }
    });
    return courseData;
  }

  segregateUserCourses(courses) {
    const activeCourses = courses.filter(course => !course.isExpired);
    const expiredCourses = courses.filter(course => course.isExpired);

    return {activeCourses, expiredCourses};
  }
  
  fetchMyGoalData(clearCachedCourse: boolean = false): any {
    return new Promise((resolve, reject) => {
      if (clearCachedCourse) this.http.cachedCourses = null;
      this.http.get('v4/auth/users/courses', true, null, true, false
      ).subscribe((res) => {
        if (res && res.code === 200) {
          resolve(res.data);
        } else {
          this.alert.showErrorMessage(res.message);
        }
      });
    });
  }

  fetchGoalHistoryGraphData(courseId): any {
    return new Promise((resolve, reject) => {
      this.http.get('v4/auth/users/course/' + courseId + '/projections', true, null, true, false).subscribe((res) => {
          if (res && res.code === 200) {
            resolve(res.data);
          } else {
            this.alert.showErrorMessage(res.message);
          }
        });
    });
  }

  fetchAllAvailableCourses(): any {
    return new Promise((resolve, reject) => {
      this.http.get('v4/auth/users/available-courses', true, null, true, false).subscribe((res) => {
        if (res && res.code === 200) {
          resolve(res.data);
        } else {
          this.alert.showErrorMessage(res.message);
        }
      });
    });
  }

  sendAddedTargetExams(data): any {
    const dataForApi = [];
    // tslint:disable-next-line: prefer-for-of
    for (let i = 0; i < data.length; i++) {
      const obj = {
        course: {
          id: data[i].course.id,
          name: data[i].course.name
        },
        courseType: {
          id: data[i].level.courseType.id,
          name: data[i].level.courseType.name
        },
        goalLevel: {
          id: data[i].level.id,
          name: data[i].level.name
        }
      };
      dataForApi.push(obj);
    }
    return new Promise((resolve, reject) => {
      this.http.post('v4/auth/users/courses', true, { courses: dataForApi }, true).subscribe((res) => {
        if (res && res.code === 200) {
          this.alert.showSuccessMessage(res.message);
          resolve(res.data);
        } else {
          this.alert.showErrorMessage(res.message);
        }
      });
    });
  }

  getGoalLevelsForCourseId(courseId): any {
    return new Promise((resolve, reject) => {
      this.http.get('v4/auth/goal-settings/goal-level', true, { courseId }, true).subscribe((res) => {
        if (res && res.code === 200) {
          resolve(res.data);
        } else {
          this.alert.showErrorMessage(res.message);
        }
      });
    });
  }

  sendUpdatedTargetExam(payload): any {
    return new Promise((resolve, reject) => {
      this.http.put('v4/auth/users/courses', true, payload, true).subscribe((res) => {
        if (res && res.code === 200) {
          this.alert.showSuccessMessage(res.message);
          resolve(res.data);
        } else {
          this.alert.showErrorMessage(res.message);
        }
      });
    });
  }

  deleteCurrentGoal(payload): any {
    return new Promise((resolve, reject) => {
      this.http.delete('v4/auth/users/courses/' + payload.courseId, null, true).subscribe((res) => {
        if (res && res.code === 200) {
          this.alert.showSuccessMessage(res.message);
          resolve(res.data);
        } else {
          this.alert.showErrorMessage(res.message);
        }
      });
    });
  }
  getCurrentProjectList(projectTaskValues?) {
    return new Promise((resolve, reject) => {
      this.http.post('v4/auth/current-projection/setting',true,projectTaskValues,true).subscribe(res => {
        if (res && res.code === 200) {
          resolve(res.message);
        } else {
          this.alert.showErrorMessage(res.message);
        }
      });
    })
  }

  getCurrentProjectData(){
    return new Promise((resolve, reject) => {
      this.http.get('v4/auth/users/settings',true,null,true).subscribe(res => {
        if (res && res.code === 200) {
          resolve(res.data);
        } else {
          this.alert.showErrorMessage(res.message);
        }
      });
    })
  }

  getStudentBatchHistory(): Observable<any> {
    return this.http.get('v4/auth/users/batch-history', true);
  }

  getFacultiesHistory(batchId: string): Observable<any> {
    return this.http.get('v4/auth/users/' + batchId + '/faculties-history', true);
  }

  openFacultyModal(batchId: string) {
    this.displayFacultyModalData.next(batchId);
  }

  sendBatchDetails(): Observable<any> {
    return this.displayFacultyModalData.asObservable();
  }

  updateTargetExam(body: any) {
    return this.http.put(`v4/auth/user-profile`, true, body);
  }
}
